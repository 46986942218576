import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { UserAccessLevel, UserKycStatuses } from '../../apis/users/types';

export const useAccessLevelOptions = (subscriptionsEnabled: boolean) => {
  const { t } = useTranslation('users');
  return useMemo<{ value: string; label: string }[]>(
    () =>
      [
        { value: '', label: t('common:all') },
        ...Object.entries(UserAccessLevel)
          .filter(([value]) => !Number.isNaN(+value))
          .map(([value, label]) => ({
            label: t('accessLevels.' + label),
            value,
          })),
      ].filter(
        (x) =>
          subscriptionsEnabled ||
          ![
            UserAccessLevel.NoSubscription,
            UserAccessLevel.SubscriptionExpired,
          ].includes(+x.value as unknown as UserAccessLevel),
      ),
    [t],
  );
};

export const useKYCStatusOptions = () => {
  const { t } = useTranslation('users');
  return useMemo<{ value: string; label: string }[]>(
    () => [
      { value: '', label: t('common:all') },
      ...Object.values(UserKycStatuses).map((value) => ({
        label: t('kycStatuses.' + value),
        value,
      })),
    ],
    [t],
  );
};

export const useSubscriptionOptions = () => {
  const { t } = useTranslation('users');
  return useMemo<{ value: string; label: string }[]>(
    () => [
      { value: '', label: t('common:all') },
      { value: 'true', label: t('common:yes') },
      { value: 'false', label: t('common:no') },
    ],
    [t],
  );
};

export const useSubscriptionExpiryOptions = () => {
  const { t } = useTranslation('users');
  return useMemo<{ value: string; label: string }[]>(
    () => [
      { value: '', label: t('common:all') },
      { value: 'false', label: t('table.active') },
      { value: 'true', label: t('table.expired') },
    ],
    [t],
  );
};
