export type UsersState = {};

export type UserData = {
  '2faEnabled': boolean;
  accessLevel: number;
  email: string;
  userId: string;
  kyc:
    | {
        status: UserKycStatuses | null;
        level: string | null;
        reason: string | null;
        canBeRetried: boolean | null;
      }[]
    | null;
  subscription: {
    plan: string;
    duration: number;
    expirationDate: string;
  } | null;
};

export type UserFilterType = {
  id?: string;
  email?: string;
  access?: string;
  kycStatus?: string;
  subscription?: string;
  subscriptionExpired?: string;
};

export type UserActionPayloadType = {
  userId: string;
};

// yes copypaste from ps2, sorry
export enum UserAccessLevel {
  Banned = -100,
  NotVerified = 100,
  KycPending = 350,
  NoSubscription = 375,
  Frozen = 400,
  KycExpired = 450,
  SubscriptionExpired = 475,
  Normal = 500,
  Support = 700,
  Admin = 900,
}
export enum UserKycStatuses {
  Init = 'Init',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Expired = 'Expired',
  'Rejected with retry' = 'Rejected with retry',
}
