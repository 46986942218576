import React from 'react';
import { useIsAuthenticated } from '../../apis/session/use';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../routes';
import WhitelabelConfigLoader from '../../components/WhitelabelConfigLoader';

const AuthenticatedWall: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  return isAuthenticated ? (
    <WhitelabelConfigLoader>
      <Outlet />
    </WhitelabelConfigLoader>
  ) : (
    <Navigate to={ROUTE_LOGIN} replace state={{ redirectTo: location }} />
  );
};

export default AuthenticatedWall;
