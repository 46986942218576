import * as yup from 'yup';

export const EditEmailValidation = yup
  .object({
    email: yup
      .string()
      .required('auth:error.required')
      .email('auth:error.email-invalid')
      .test('matchesOld', 'users:edit-email.same-as-old', function (value) {
        return this.parent.originalEmail !== value;
      }),
  })
  .required();
