import { Box, styled } from '@mui/system';

const TableWrap = styled(Box)`
  max-width: calc(100vw - 50px);
  width: 100%;
  & table {
    min-width: 1000px;
  }
`;

export const TableFilterWrap = styled(Box)`
  max-width: calc(100vw - 50px);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
  & > * {
    flex: 1;
  }
`;

export default TableWrap;
