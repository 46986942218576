import baseApiBackoffice from '../baseApiBackoffice';
import { serialize } from 'object-to-formdata';
import { WhitelabelBackendConfig } from './types';
import { injectEndpoints } from '../util';

export const api = injectEndpoints(baseApiBackoffice, (builder) => ({
  wlConfig: builder.query<WhitelabelBackendConfig, string>({
    query: (slug) => ({
      url: `wl_configuration/${slug}`,
      method: 'GET',
    }),
    providesTags: ['WlConfig'],
  }),
  saveWlConfig: builder.mutation<
    WhitelabelBackendConfig,
    { slug: string; data: WhitelabelBackendConfig }
  >({
    invalidatesTags: ['WlConfig'],
    query: ({ slug, data }) => {
      // @ts-ignore
      data.settings = JSON.stringify(data.settings);
      // Uncomment this to make it work until the backend gets fixed
      // delete data.marketplaceMinScore;
      return {
        url: `wl_configuration/${slug}`,
        method: 'POST',
        formData: true,
        body: serialize(data),
      };
    },
  }),
  bustWlCache: builder.mutation<void, string>({
    query: (domain) => ({
      url: `https://${domain}/server-cache-bust`,
      method: 'POST',
      body: {
        token: import.meta.env.VITE_WL_CACHE_INVALIDATION_TOKEN,
      },
    }),
  }),
}));

export const {
  useSaveWlConfigMutation,
  useBustWlCacheMutation,
  useWlConfigQuery,
} = api;
