export const getIconBase = (color: string) => {
  return (
    "data:image/svg+xml;base64," +
    btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
      <defs>
          <filter id="shadow">
              <feDropShadow result="drop-shadow-0" in="SourceGraphic" style="flood-opacity: 0.3;" stdDeviation="10" dx="0" dy="10"/>
          </filter>
      </defs>
      <g style="filter: url(#shadow);">
          <path style="stroke-width: 3px; fill: ${color};" d="M 100 724 L 100 300 C 100 185.001 170 99.995 300.026 100 L 724 99.995 C 854 100 924 185.001 924 300 L 924 724 C 924 839.001 854 923.995 724 924 L 300.026 924 C 170 924 100 839.001 100 724 Z"></path>
      </g>
  </svg>
  `)
  );
};
