import React from 'react';
import { Loader } from '@zignaly-open/ui';
import { useUserInfoQuery } from '../apis/session/api';
import { useWlConfigQuery } from '../apis/config/api';
import { Box } from '@mui/system';

function WhitelabelConfigLoader({ children }: { children: JSX.Element }) {
  const { isLoading: isLoadingUserData, data } = useUserInfoQuery();
  const { isLoading, data: wlData } = useWlConfigQuery(data?.projectIds?.[0], {
    skip: !data?.projectIds?.[0],
  });

  return isLoading || isLoadingUserData || !wlData ? (
    <Box
      sx={{
        py: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </Box>
  ) : (
    children
  );
}

export default WhitelabelConfigLoader;
