import { getIconBase } from "./shadowSvg";

export type PwaImageType = "icon" | "splash" | "macos-icon";

export async function createPwaLogoImage(
  logo: string | File,
  bgColor: string,
  type: PwaImageType,
  {
    width,
    height,
    logoWidth,
    logoHeight,
  }: { width: number; height: number; logoWidth: number; logoHeight?: number },
): Promise<File | null> {
  try {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    if (type === "macos-icon") {
      context.globalAlpha = 0;
    }
    canvas.width = width;
    canvas.height = height;
    const imagesToLoad = [
      typeof logo === "string" ? logo + "?crossorigin" : URL.createObjectURL(logo),
      getIconBase(bgColor),
    ];

    const [logoImg, shadow] = await Promise.all(
      imagesToLoad.map((src) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => resolve(img);
          img.onerror = (e) => reject(e);
        });
      }),
    );

    if (type === "macos-icon") {
      context.drawImage(shadow, 0, 0, width, height);
    } else {
      context.fillStyle = bgColor;
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    context.drawImage(
      logoImg,
      width / 2 - logoWidth / 2,
      height / 2 - (logoHeight || logoWidth) / 2,
      logoWidth,
      logoHeight || logoWidth,
    );

    return await convertCanvasToFile(canvas);
  } catch (e) {
    console.error(e);
    return null;
  }
}

function convertCanvasToFile(canvas: HTMLCanvasElement): Promise<File> {
  return new Promise((r) =>
    canvas.toBlob((blob) => {
      // @ts-ignore
      r(new File([blob], "image.png"));
    }, "image/png"),
  );
}
