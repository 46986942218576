import { showZigToast } from '@zignaly-open/ui';
import { RootState } from './store';
import { clearUserSession } from './session/util';
import {
  fetchBaseQuery,
  FetchBaseQueryError,
  BaseQueryFn,
  FetchArgs,
} from '@reduxjs/toolkit/query';

const baseQuery = (
  baseUrl = import.meta.env.VITE_BASE_API,
  noDefaultJsonType = false,
) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).session?.accessToken;
      if (token && !['login', 'signup'].includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      // sorry
      // the reason we do this is to not set the json type when we pass formData
      if (!noDefaultJsonType && (!headers || !headers.get('Content-Type'))) {
        headers.set('content-type', 'application/json');
      }
      return headers;
    },
  });

const endpointsWhitelistedFor401 = [`login`, `logout`];

const maybeReportError = (error: FetchBaseQueryError) => {
  if (!error) return;
  // @ts-ignore
  showZigToast('error')(error?.data?.error?.msg || 'Something went wrong');
};

const customFetchBase: (
  baseUrl?: string,
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  (baseUrl) => async (args, api, extraOptions) => {
    const result = await baseQuery(
      baseUrl,
      // @ts-ignore
      'formData' in args && args.formData,
    )(args, api, extraOptions);
    maybeReportError(result?.error);

    if (
      result?.error?.status === 401 &&
      // @ts-ignore
      !endpointsWhitelistedFor401.includes(args.url)
    ) {
      clearUserSession(api.dispatch);
    }

    return result;
  };

export default customFetchBase;
